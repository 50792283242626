import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"

import styles from './header.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import logo from '../images/common/logo.png'

const Header = ({ siteTitle }) => {

  const [open, setOpen] = useState(false)

  const toggle = () => setOpen(!open)

  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <h1 style={{ margin: 0 }}>
          <Link to="/" >
            <img src={logo} alt={siteTitle} />
          </Link>
        </h1>
      </div>
      <div className={styles.menu}>
        <button
          onClick={toggle}
          className={styles.navOpen}
        >
          <FontAwesomeIcon icon={faBars} />
        </button>
        <div className={
          open ? 
            [styles.navContainer, styles.open].join(' ') : 
            styles.navContainer
        }>
          <button onClick={toggle} className={styles.navClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          <nav>
            <Link to="/">TOP</Link>
          </nav>
          <nav>
            <Link to="/about">ABOUT</Link>
          </nav>
          <nav>
            <Link to="/service">SERVICE</Link>
          </nav>
          <nav>
            <Link to="/members">MEMBERS</Link>
          </nav>
          <nav>
            <Link to="/contact">CONTACT</Link>
          </nav>
          <nav>
            <Link to="/qanda">Q&A</Link>
          </nav>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
