import React from "react"
import { Link } from "gatsby"

import "./footer.css";

import Signature from '../images/footer/signature.png';
import Icn_mail from '../images/footer/icn_mail.png';
import Sign_contact from '../images/footer/sign_contact.png';
import Icn_home from '../images/footer/icn_home.png';
import Icn_facebook from '../images/footer/icn_facebook.png';
import Icn_youtube from '../images/footer/icn_youtube.png';
import Icn_instagram from '../images/footer/icn_instagram.png';

const Footer  = ({ copyright }) => (
  <footer>
    <div className="container">
      <p className="signature">
        <img alt=" " src={Signature} className="scale-to-fit" />
      </p>
      <div className="contact">
        <Link to="/contact">
          <p className="icon">
            <img alt=" " src={Icn_mail} className="scale-to-fit" />
          </p>
          <p className="text">CONTACT</p>
          <p className="sign">
            <img alt=" " src={Sign_contact} className="scale-to-fit" />
          </p>
        </Link>
      </div>
      <ul className="links">
        <li>
          <Link to="/">
            <img alt=" " src={Icn_home} className="scale-to-fit" />
          </Link>
        </li>
        <li>
          <a href="https://www.facebook.com/hoshinamilly/" target="_blank" rel="noreferrer">
            <img alt=" " src={Icn_facebook} className="scale-to-fit" />
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/watch?app=desktop&v=-1eSiSdam3c&feature=youtu.be" target="_blank" rel="noreferrer">
            <img alt=" " src={Icn_youtube} className="scale-to-fit" />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/hoshina__milly/" target="_blank" rel="noreferrer">
            <img alt=" " src={Icn_instagram} className="scale-to-fit" />
          </a>
        </li>
      </ul>
      <div className="copyright">
        <p>Copyright &copy;</p>
        <p>{new Date().getFullYear()} {copyright}&reg; </p>
        <p>All Reserved</p>
      </div>
    </div>
  </footer>
)

export default Footer
